import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import {CircularProgress} from '@mui/material';
import download from 'downloadjs';

const config = {
    'English/US': {
        name: 'English/US',
        resultsSecondCallToActionOnClick: (setEmailDialog, createFittingReport, translations) => {
            setEmailDialog(true);
        },
        resultsSecondCallToActionCopy: (translations, downloadingReport) => {
            return (
                <>
                    <EmailIcon sx={{marginRight: '7px'}} /> {translations.ResultsSecondCallToAction}
                </>
            );
        },
        orderSlipBottomCallToAction: translations => {
            return <> {translations.OrderSlipOrderCopy2}</>;
        },
        bagChoices: true,
        termsOfUseLink: 'https://ping.com/en-us/terms-of-use',
        privacyPolicyLink: 'https://ping.com/en-us/privacy',
        flag: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/us-flag.svg',
        disabledFaqQuestions: [],
        bagDescription: translations => {
            return <>{translations.BagCardDescription}</>;
        },
        orderOnlineShort: 'https://ping.com/en-us/clubs/juniors/prodi-g-custom-set-40in-56in',
        orderOnlineTall: 'https://ping.com/en-us/clubs/juniors/prodi-g-custom-set-57in-62in',
        findFitter: 'https://ping.com/en-us/find-a-fitter',
        fittingReportFontSize: '18px',
        gappingReportFontSize: '22px',
        yardageDisclaimerHeight: 451,
        playerNameSize: 11,
        qrFontSize: 8,
        gappingDisclaimerFontSize: 6,
        wristCreaseLink: true,
        addToCart: true
    },
    '地域/言語': {
        name: '地域/言語',
        resultsSecondCallToActionOnClick: async (setEmailDialog, createFittingReport, translations) => {
            let report = await createFittingReport();
            download(report, `${translations.FittingReportFileName}.pdf`, 'application/pdf');
        },
        resultsSecondCallToActionCopy: (translations, downloadingReport) => {
            return (
                <>
                    {' '}
                    {downloadingReport ? (
                        <CircularProgress size={'20px'} />
                    ) : (
                        <>
                            <DownloadIcon sx={{marginRight: '7px', fontSize: '20px'}} />
                            {translations.DownloadReportSecondCallToAction}
                        </>
                    )}
                </>
            );
        },
        orderSlipBottomCallToAction: translations => {
            return <> {translations.OrderSlipOrderCopy2}</>;
        },
        bagChoices: false,
        termsOfUseLink: 'https://ping.com/en-us/terms-of-use',
        privacyPolicyLink: 'https://ping.com/en-us/privacy',
        flag: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/jp-flag.svg',
        disabledFaqQuestions: [14],
        bagDescription: translations => {
            return <>{translations.BagCardDescription}</>;
        },
        orderOnlineShort: 'https://ping.com/en-us/clubs/juniors',
        orderOnlineTall: 'https://ping.com/en-us/clubs/juniors',
        findFitter: 'https://ping.com',
        fittingReportFontSize: '10px',
        gappingReportFontSize: '18px',
        yardageDisclaimerHeight: 459,
        playerNameSize: 11,
        qrFontSize: 8,
        gappingDisclaimerFontSize: 6,
        wristCreaseLink: false,
        addToCart: false
    },
    'English/CA': {
        name: 'English/CA',
        resultsSecondCallToActionOnClick: (setEmailDialog, createFittingReport, translations) => {
            setEmailDialog(true);
        },
        resultsSecondCallToActionCopy: (translations, downloadingReport) => {
            return (
                <>
                    <EmailIcon sx={{marginRight: '7px'}} /> {translations.ResultsSecondCallToAction}
                </>
            );
        },
        orderSlipBottomCallToAction: translations => {
            return <> {translations.OrderSlipOrderCopy2}</>;
        },
        bagChoices: true,
        termsOfUseLink: 'https://ca.ping.com/en-ca/legal/terms-of-use',
        privacyPolicyLink: 'https://ca.ping.com/en-ca/legal/privacy',
        flag: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ca-flag.svg',
        disabledFaqQuestions: [],
        bagDescription: translations => {
            return <>{translations.BagCardDescription}</>;
        },
        orderOnlineShort: 'https://ca.ping.com/en-ca/promo-landing-pages/prodi-g',
        orderOnlineTall: 'https://ca.ping.com/en-ca/promo-landing-pages/prodi-g',
        findFitter: 'https://ca.ping.com/en-ca/find-retailers',
        fittingReportFontSize: '18px',
        gappingReportFontSize: '22px',
        yardageDisclaimerHeight: 451,
        playerNameSize: 11,
        qrFontSize: 8,
        gappingDisclaimerFontSize: 6,
        wristCreaseLink: true,
        addToCart: true
    },
    'Français/CA': {
        name: 'Français/CA',
        resultsSecondCallToActionOnClick: (setEmailDialog, createFittingReport, translations) => {
            setEmailDialog(true);
        },
        resultsSecondCallToActionCopy: (translations, downloadingReport) => {
            return (
                <>
                    <EmailIcon sx={{marginRight: '7px'}} /> {translations.ResultsSecondCallToAction}
                </>
            );
        },
        orderSlipBottomCallToAction: translations => {
            return <> {translations.OrderSlipOrderCopy2}</>;
        },
        bagChoices: true,
        termsOfUseLink: 'https://ca.ping.com/en-ca/legal/terms-of-use',
        privacyPolicyLink: 'https://ca.ping.com/en-ca/legal/privacy',
        flag: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ca-flag.svg',
        disabledFaqQuestions: [],
        bagDescription: translations => {
            return <>{translations.BagCardDescription}</>;
        },
        orderOnlineShort: 'https://ca.ping.com/en-ca/promo-landing-pages/prodi-g',
        orderOnlineTall: 'https://ca.ping.com/en-ca/promo-landing-pages/prodi-g',
        findFitter: 'https://ca.ping.com/en-ca/find-retailers',
        fittingReportFontSize: '16px',
        gappingReportFontSize: '18px',
        yardageDisclaimerHeight: 451,
        playerNameSize: 9,
        qrFontSize: 6,
        gappingDisclaimerFontSize: 4,
        wristCreaseLink: true,
        addToCart: true
    },
    'Français/FR': {
        name: 'Français/FR',
        resultsSecondCallToActionOnClick: async (setEmailDialog, createFittingReport, translations) => {
            let report = await createFittingReport();
            download(report, `${translations.FittingReportFileName}.pdf`, 'application/pdf');
        },
        resultsSecondCallToActionCopy: (translations, downloadingReport) => {
            return (
                <>
                    {' '}
                    {downloadingReport ? (
                        <CircularProgress size={'20px'} />
                    ) : (
                        <>
                            <DownloadIcon sx={{marginRight: '7px', fontSize: '20px'}} />
                            {translations.DownloadReportSecondCallToAction}
                        </>
                    )}
                </>
            );
        },
        orderSlipBottomCallToAction: translations => {
            return <> {translations.DownloadReportSecondCallToAction}</>;
        },
        bagChoices: false,
        termsOfUseLink: 'https://eu.ping.com/en-gb/terms-of-use',
        privacyPolicyLink: 'https://eu.ping.com/en-gb/privacy',
        flag: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/fr-flag.svg',
        disabledFaqQuestions: [10, 14],
        bagDescription: translations => {
            return <>{translations.BagCardDescriptionWithoutGreenWhiteBag}</>;
        },
        orderOnlineShort: 'https://eu.ping.com/en-gb/',
        orderOnlineTall: 'https://eu.ping.com/en-gb/',
        findFitter: 'https://eu.ping.com/en-gb/',
        fittingReportFontSize: '18px',
        gappingReportFontSize: '14px',
        yardageDisclaimerHeight: 451,
        playerNameSize: 8,
        qrFontSize: 6,
        gappingDisclaimerFontSize: 4,
        wristCreaseLink: true,
        addToCart: false
    },
    'English/GB': {
        name: 'English/GB',
        resultsSecondCallToActionOnClick: async (setEmailDialog, createFittingReport, translations) => {
            let report = await createFittingReport();
            download(report, `${translations.FittingReportFileName}.pdf`, 'application/pdf');
        },
        resultsSecondCallToActionCopy: (translations, downloadingReport) => {
            return (
                <>
                    {' '}
                    {downloadingReport ? (
                        <CircularProgress size={'20px'} />
                    ) : (
                        <>
                            <DownloadIcon sx={{marginRight: '7px', fontSize: '20px'}} />
                            {translations.DownloadReportSecondCallToAction}
                        </>
                    )}
                </>
            );
        },
        orderSlipBottomCallToAction: translations => {
            return <> {translations.DownloadReportSecondCallToAction}</>;
        },
        bagChoices: false,
        termsOfUseLink: 'https://eu.ping.com/en-gb/terms-of-use',
        privacyPolicyLink: 'https://eu.ping.com/en-gb/privacy',
        flag: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/gb-flag.svg',
        disabledFaqQuestions: [10, 14],
        bagDescription: translations => {
            return <>{translations.BagCardDescriptionWithoutGreenWhiteBag}</>;
        },
        orderOnlineShort: 'https://eu.ping.com/en-gb/',
        orderOnlineTall: 'https://eu.ping.com/en-gb/',
        findFitter: 'https://eu.ping.com/en-gb/',
        fittingReportFontSize: '18px',
        gappingReportFontSize: '22px',
        yardageDisclaimerHeight: 451,
        playerNameSize: 11,
        qrFontSize: 8,
        gappingDisclaimerFontSize: 6,
        wristCreaseLink: true,
        addToCart: false
    },
    'Svenska/SE': {
        name: 'Svenska/SE',
        resultsSecondCallToActionOnClick: async (setEmailDialog, createFittingReport, translations) => {
            let report = await createFittingReport();
            download(report, `${translations.FittingReportFileName}.pdf`, 'application/pdf');
        },
        resultsSecondCallToActionCopy: (translations, downloadingReport) => {
            return (
                <>
                    {' '}
                    {downloadingReport ? (
                        <CircularProgress size={'20px'} />
                    ) : (
                        <>
                            <DownloadIcon sx={{marginRight: '7px', fontSize: '20px'}} />
                            {translations.DownloadReportSecondCallToAction}
                        </>
                    )}
                </>
            );
        },
        orderSlipBottomCallToAction: translations => {
            return <> {translations.DownloadReportSecondCallToAction}</>;
        },
        bagChoices: false,
        termsOfUseLink: 'https://eu.ping.com/en-gb/terms-of-use',
        privacyPolicyLink: 'https://eu.ping.com/en-gb/privacy',
        flag: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/se-flag.svg',
        disabledFaqQuestions: [10, 14],
        bagDescription: translations => {
            return <>{translations.BagCardDescriptionWithoutGreenWhiteBag}</>;
        },
        orderOnlineShort: 'https://eu.ping.com/en-gb/',
        orderOnlineTall: 'https://eu.ping.com/en-gb/',
        findFitter: 'https://eu.ping.com/en-gb/',
        fittingReportFontSize: '18px',
        gappingReportFontSize: '22px',
        yardageDisclaimerHeight: 451,
        playerNameSize: 11,
        qrFontSize: 7,
        gappingDisclaimerFontSize: 5,
        wristCreaseLink: true,
        addToCart: false
    },
    'Español/ES': {
        name: 'Español/ES',
        resultsSecondCallToActionOnClick: async (setEmailDialog, createFittingReport, translations) => {
            let report = await createFittingReport();
            download(report, `${translations.FittingReportFileName}.pdf`, 'application/pdf');
        },
        resultsSecondCallToActionCopy: (translations, downloadingReport) => {
            return (
                <>
                    {' '}
                    {downloadingReport ? (
                        <CircularProgress size={'20px'} />
                    ) : (
                        <>
                            <DownloadIcon sx={{marginRight: '7px', fontSize: '20px'}} />
                            {translations.DownloadReportSecondCallToAction}
                        </>
                    )}
                </>
            );
        },
        orderSlipBottomCallToAction: translations => {
            return <> {translations.DownloadReportSecondCallToAction}</>;
        },
        bagChoices: false,
        termsOfUseLink: 'https://eu.ping.com/en-gb/terms-of-use',
        privacyPolicyLink: 'https://eu.ping.com/en-gb/privacy',
        flag: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/es-flag.svg',
        disabledFaqQuestions: [10, 14],
        bagDescription: translations => {
            return <>{translations.BagCardDescriptionWithoutGreenWhiteBag}</>;
        },
        orderOnlineShort: 'https://eu.ping.com/en-gb/',
        orderOnlineTall: 'https://eu.ping.com/en-gb/',
        findFitter: 'https://eu.ping.com/en-gb/',
        fittingReportFontSize: '18px',
        gappingReportFontSize: '22px',
        yardageDisclaimerHeight: 451,
        playerNameSize: 9,
        qrFontSize: 6,
        gappingDisclaimerFontSize: 4,
        wristCreaseLink: true,
        addToCart: false
    },
    'Deutsch/DE': {
        name: 'Deutsch/DE',
        resultsSecondCallToActionOnClick: async (setEmailDialog, createFittingReport, translations) => {
            let report = await createFittingReport();
            download(report, `${translations.FittingReportFileName}.pdf`, 'application/pdf');
        },
        resultsSecondCallToActionCopy: (translations, downloadingReport) => {
            return (
                <>
                    {' '}
                    {downloadingReport ? (
                        <CircularProgress size={'20px'} />
                    ) : (
                        <>
                            <DownloadIcon sx={{marginRight: '7px', fontSize: '20px'}} />
                            {translations.DownloadReportSecondCallToAction}
                        </>
                    )}
                </>
            );
        },
        orderSlipBottomCallToAction: translations => {
            return <> {translations.DownloadReportSecondCallToAction}</>;
        },
        bagChoices: false,
        termsOfUseLink: 'https://eu.ping.com/en-gb/terms-of-use',
        privacyPolicyLink: 'https://eu.ping.com/en-gb/privacy',
        flag: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/de-flag.svg',
        disabledFaqQuestions: [10, 14],
        bagDescription: translations => {
            return <>{translations.BagCardDescriptionWithoutGreenWhiteBag}</>;
        },
        orderOnlineShort: 'https://eu.ping.com/en-gb/',
        orderOnlineTall: 'https://eu.ping.com/en-gb/',
        findFitter: 'https://eu.ping.com/en-gb/',
        fittingReportFontSize: '18px',
        gappingReportFontSize: '22px',
        yardageDisclaimerHeight: 451,
        playerNameSize: 9,
        qrFontSize: 6,
        gappingDisclaimerFontSize: 4,
        wristCreaseLink: true,
        addToCart: false
    },
};

export default config;
