import React, {useContext, useRef} from 'react';
import {RegionStateContext} from '../RegionContext';
import {Box, Tab, Tabs, Typography} from '@mui/material';
import config from '../config';

function StepperComponent({
    translations,
    colors,
    stage,
    setStage,
    setFetchingResults,
    putterPreference,
    currentPages,
    setCurrentPages,
    defaultOrder,
    bagPreference,
    setFlightData,
    getClubDetails,
    setLogChange,
    pageCompleted,
    analyticsStored
}) {
    const regionState = useContext(RegionStateContext);
    const regionConfig = config[regionState.name];
    const refProfile = useRef(null);
    const refBiometrics = useRef(null);
    const refDistance = useRef(null);
    const refPutter = useRef(null);
    const refBag = useRef(null);
    const refResults = useRef(null);

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '50px',
                backgroundColor: colors[0],
                paddingBottom: '7px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Tabs
                value={stage}
                onChange={(e, val) => {
                    pageCompleted[stage][currentPages[stage]] = true;
                    
                    pageCompleted[val][currentPages[val]] = false;
                    analyticsStored[val][currentPages[val]] = false;
                    
                    console.log(pageCompleted);
                    if (val === 5) {
                        setFetchingResults(true);
                        setFlightData(null);
                        getClubDetails();
                    } else {
                        setFetchingResults(false);
                    }
                    setStage(val);
                    setCurrentPages(defaultOrder);
                }}
                aria-label={translations.StepperAriaLabel}
                centered
                sx={{display: {xs: 'none', sm: 'flex'}}}
            >
                <Tab
                    ref={refProfile}
                    value={0}
                    label={
                        <Typography variant='boldCopy' style={{fontSize: '15px', color: colors[1], letterSpacing: '1px'}}>
                            {translations.StepperProfile}
                        </Typography>
                    }
                />
                <Tab
                    ref={refBiometrics}
                    value={1}
                    label={
                        <Typography variant='boldCopy' style={{fontSize: '15px', color: colors[1], letterSpacing: '1px'}}>
                            {translations.StepperBiometrics}
                        </Typography>
                    }
                />
                <Tab
                    ref={refDistance}
                    value={2}
                    label={
                        <Typography variant='boldCopy' style={{fontSize: '15px', color: colors[1], letterSpacing: '1px'}}>
                            {translations.StepperDistance}
                        </Typography>
                    }
                />
                <Tab
                    ref={refPutter}
                    value={3}
                    label={
                        <Typography variant='boldCopy' style={{fontSize: '15px', color: colors[1], letterSpacing: '1px'}}>
                            {translations.StepperPutter}
                        </Typography>
                    }
                />
                {regionConfig.bagChoices && (
                    <Tab
                        ref={refBag}
                        value={4}
                        label={
                            <Typography variant='boldCopy' style={{fontSize: '15px', color: colors[1], letterSpacing: '1px'}}>
                                {translations.StepperBag}
                            </Typography>
                        }
                        disabled={!putterPreference}
                    />
                )}
                <Tab
                    ref={refResults}
                    value={5}
                    label={
                        <Typography variant='boldCopy' style={{fontSize: '15px', color: colors[1], letterSpacing: '1px'}}>
                            {translations.StepperResults}
                        </Typography>
                    }
                    disabled={!putterPreference || !bagPreference}
                />
            </Tabs>
            <Tabs
                value={stage}
                onChange={(e, val) => {
                    pageCompleted[stage][currentPages[stage]] = true;
                    
                    pageCompleted[val][currentPages[val]] = false;
                    analyticsStored[val][currentPages[val]] = false;

                    console.log(pageCompleted);
                    if (val === 5) {
                        setFetchingResults(true);
                        setFlightData(null);
                        getClubDetails();
                    } else {
                        setFetchingResults(false);
                    }
                    setStage(val);
                    setLogChange(true);
                    setCurrentPages(defaultOrder);
                }}
                aria-label={translations.StepperAriaLabel}
                variant='scrollable'
                sx={{display: {xs: 'flex', sm: 'none'}}}
            >
                <Tab
                    value={0}
                    label={
                        <Typography variant='boldCopy' style={{fontSize: '15px', color: colors[1], letterSpacing: '1px'}}>
                            {translations.StepperProfile}
                        </Typography>
                    }
                />
                <Tab
                    value={1}
                    label={
                        <Typography variant='boldCopy' style={{fontSize: '15px', color: colors[1], letterSpacing: '1px'}}>
                            {translations.StepperBiometrics}
                        </Typography>
                    }
                />
                <Tab
                    value={2}
                    label={
                        <Typography variant='boldCopy' style={{fontSize: '15px', color: colors[1], letterSpacing: '1px'}}>
                            {translations.StepperDistance}
                        </Typography>
                    }
                />
                <Tab
                    value={3}
                    label={
                        <Typography variant='boldCopy' style={{fontSize: '15px', color: colors[1], letterSpacing: '1px'}}>
                            {translations.StepperPutter}
                        </Typography>
                    }
                />
                {regionConfig.bagChoices && (
                    <Tab
                        value={4}
                        label={
                            <Typography variant='boldCopy' style={{fontSize: '15px', color: colors[1], letterSpacing: '1px'}}>
                                {translations.StepperBag}
                            </Typography>
                        }
                        disabled={!putterPreference}
                    />
                )}
                <Tab
                    value={5}
                    label={
                        <Typography variant='boldCopy' style={{fontSize: '15px', color: colors[1], letterSpacing: '1px'}}>
                            {translations.StepperResults}
                        </Typography>
                    }
                    disabled={!putterPreference || !bagPreference}
                />
            </Tabs>
        </Box>
    );
}

export default StepperComponent;
