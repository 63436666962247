import React, {useEffect, useState} from 'react';
import {Drawer, Box, Typography, Button, IconButton, Tooltip, Card, Checkbox, FormControlLabel} from '@mui/material';
import {Close, InfoOutlined, Circle, Check} from '@mui/icons-material';
//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import cartMap from '../util/sendToPing';
//import { clubArray } from '../test_club_array';


function AddToCartDrawerElement({
    translations,
    colors,
    open,
    toggleDrawer,
    playerDexterity,
    flightData,
    selectedSet,
    getSubstringBeforeSpace,
    bagPreference,
    putterPreference,
    bagInfo
}) {
    const order = ['Dr', '3W', '5H', '6i', '7i', '8i', '9i', 'PW', '54', '58', 'P', 'Bag'];
    const [yourOptions, setYourOptions] = useState(null);
    const [listOfSelectedClubs, setListOfSelectedClubs] = useState([null, null, null, null, null, null, null, null, null, null, null, null]);
    const [selectedClubObjects, setSelectedClubObjects] = useState([]);

    const dexterityKey = {
        0: translations.DexterityOption1,
        1: translations.DexterityOption2
    };

    function extractBeforeFirstSpace(str) {
        const firstSpaceIndex = str.indexOf(' ');

        if (firstSpaceIndex === -1) {
            return str;
        }

        return str.substring(0, firstSpaceIndex);
    }

    function extractAfterFirstSpace(str) {
        const firstSpaceIndex = str.indexOf(' ');

        if (firstSpaceIndex === -1) {
            return '';
        }

        return str.substring(firstSpaceIndex + 1);
    }

    function convertToObjectArray(array) {
        let tempArray = JSON.parse(JSON.stringify(array));
        return tempArray.filter(item => item !== null).map(item => ({itemName: item}));
    }

    useEffect(() => {
        if (!flightData?.lc_df) return;
        if (!flightData?.set_config_df) return;
        if (flightData?.set_config_df.length < 1) return;
        if (!selectedSet) return;
        let bag = JSON.parse(JSON.stringify(flightData.lc_df));

        if (bag.length !== 12) {
            toggleDrawer(false);
            return;
        }

        let col = getSubstringBeforeSpace(bagPreference) === 'Black' ? 'Black' : 'White';

        let size = bagInfo.Black.images[0].includes('Small') ? 'Small' : 'Large';

        bag.at(-1).preference = bagPreference;
        bag.at(-1).image = `ProdiG_2024_Bag_${size}_${col}_Spine.png`;
        bag.at(-2).variant = putterPreference;
        bag.at(-2).image = `${putterPreference.toLowerCase()}Cavity.png`;

        let lieIndex = bag.findIndex(e => e.image.includes('iron'));
        bag.at(-2).color = bag[lieIndex].color || '#050503';
        bag.at(-2).lie = bag[lieIndex].lie || 'Black (Standard)';
        setYourOptions(bag);
        let list = [...JSON.parse(JSON.stringify(flightData?.set_config_df[0][selectedSet])), 'P', 'Bag'];

        const defaultOrder = ['Dr', '3W', '5H', '6i', '7i', '8i', '9i', 'PW', '54', '58', 'P', 'Bag'];
        let currentSelection = [null, null, null, null, null, null, null, null, null, null, null, null];
        for (let i = 0; i < list.length; i++) {
            const correctIndex = defaultOrder.indexOf(list[i]);
            currentSelection[correctIndex] = list[i];
        }
        setListOfSelectedClubs(currentSelection);
        setSelectedClubObjects(convertToObjectArray(currentSelection));
    }, [flightData, putterPreference, bagPreference, bagInfo, getSubstringBeforeSpace, selectedSet, toggleDrawer]);

    function changeSelection(club) {
        let currentSelection = JSON.parse(JSON.stringify(listOfSelectedClubs));
        const correctIndex = order.indexOf(club);
        if (currentSelection[correctIndex] === null) {
            currentSelection[correctIndex] = club;
        } else {
            currentSelection[correctIndex] = null;
        }
        setListOfSelectedClubs(currentSelection);
        setSelectedClubObjects(convertToObjectArray(currentSelection));
    }

    return (
        <>
            <Drawer
                open={open}
                anchor='right'
                onClose={() => {
                    toggleDrawer(false);
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: colors[9]
                    },
                    '& .MuiModal-backdrop': {
                        backgroundColor: 'rgba(0, 0, 0, 0.85)'
                    }
                }}
            >
                <Box
                    role='presentation'
                    sx={{
                        width: '780px',
                        maxWidth: {xs: '100vw', sm: '90vw'},
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        flexGrow: '1'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            toggleDrawer(false);
                        }}
                        sx={{position: 'absolute', top: '0.5rem', right: '5px', color: 'white'}}
                    >
                        <Close />
                    </IconButton>
                    <Typography
                        variant='semiBoldCopy'
                        style={{
                            margin: '1.5rem 0 0.5rem 0',
                            width: '100%',
                            textAlign: 'center',
                            fontSize: '1.25rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: colors[1]
                        }}
                    >
                        {translations.OrderSlipDrawerTitle}
                    </Typography>
                    <Box
                        style={{
                            margin: '0 0 2rem 0',
                            width: '100%',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                color: colors[1],
                                width: '420px',
                                maxWidth: '80%'
                            }}
                        >
                            {translations.AddToCartSubHeading}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            marginLeft: '5%',
                            width: '90%',
                            gap: '18px',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                color: colors[1],
                                gap: '18px',
                                fontSize: '0.9rem'
                            }}
                        >
                            <Typography sx={{fontSize: '0.9rem'}}>
                                {translations.Dexterity}: {dexterityKey[playerDexterity]}
                            </Typography>
                            {yourOptions?.find(e => e.ClubName.includes('7 i'))?.lie && yourOptions?.find(e => e.ClubName.includes('7 i'))?.color && (
                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '0.6rem'}}>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {translations.PingColorCode}: {extractBeforeFirstSpace(yourOptions?.find(e => e.ClubName.includes('7 i')).lie)}{' '}
                                        {extractAfterFirstSpace(yourOptions?.find(e => e.ClubName.includes('7 i')).lie)}
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: '0.8rem',
                                            height: '0.8rem',
                                            borderRadius: '50%',
                                            backgroundColor: yourOptions?.find(e => e.ClubName.includes('7 i')).color
                                        }}
                                    ></Box>
                                </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                color: colors[1],
                                gap: '8px',
                                fontSize: '0.9rem'
                            }}
                        >
                            <Typography sx={{fontSize: '0.9rem'}}>{translations.AddToCartSetMakeupTitle}:</Typography>
                            <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', gap: '5px'}}>
                                {listOfSelectedClubs?.map((e, i) => {
                                    if (e) {
                                        return (
                                            <Box
                                                sx={{
                                                    height: '35px',
                                                    width: '35px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '50%',
                                                    border: `2px solid ${colors[2]}`
                                                }}
                                            >
                                                {e === '3W' ? 'Fwy' : e === 'P' ? 'Pu' : e === '5H' ? 'Hyb' : e.replace(/54/g, '54 S').replace(/58/g, '58 H')}
                                            </Box>
                                        );
                                    } else {
                                        return <></>;
                                    }
                                })}
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '90%',
                            marginLeft: '5%',
                            marginTop: '2rem',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
                            gap: '20px'
                        }}
                    >
                        {yourOptions?.map((e, i) => {
                            if (flightData?.set_config_df[0][selectedSet].includes(e.Club) || e.ClubName === 'Bag' || e.ClubName === 'Putter') {
                                return (
                                    <>
                                        <Card
                                            variant='outlined'
                                            sx={{
                                                backgroundColor: colors[3],
                                                border: `2px solid ${colors[2]}`,
                                                boxShadow: 'rgba(255, 255, 255, 0.35) 0px 2px 7px',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                flexGrow: 1,
                                                position: 'relative'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    flexGrow: 1,
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    width: '100%'
                                                }}
                                            >
                                                <Box sx={{width: '100%', backgroundColor: colors[11], position: 'relative'}}>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            flexDirection: 'column'
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                height: '40px'
                                                            }}
                                                        >
                                                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                <FormControlLabel
                                                                    name={e.Club ? e.Club : 'Bag'}
                                                                    onClick={event => changeSelection(event.target.name)}
                                                                    sx={{color: colors[1], marginLeft: '2px', marginRight: '2px'}}
                                                                    control={
                                                                        <Checkbox
                                                                            icon={
                                                                                <Circle
                                                                                    sx={{
                                                                                        color: colors[1],
                                                                                        backgroundColor: colors[1],
                                                                                        borderRadius: '50%',
                                                                                        fontSize: '20px'
                                                                                    }}
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <Check
                                                                                    sx={{
                                                                                        backgroundColor: colors[2],
                                                                                        color: colors[1],
                                                                                        borderRadius: '50%',
                                                                                        fontSize: '20px'
                                                                                    }}
                                                                                />
                                                                            }
                                                                            checked={
                                                                                listOfSelectedClubs.includes(e.Club) ||
                                                                                (listOfSelectedClubs.includes('Bag') && e.ClubName === 'Bag')
                                                                            }
                                                                            color='primary'
                                                                            inputProps={{
                                                                                'aria-label': `${e.ClubName} ${
                                                                                    listOfSelectedClubs.includes(e.Club) ||
                                                                                    (listOfSelectedClubs.includes('Bag') && e.ClubName === 'Bag')
                                                                                        ? translations.AddToCartSelected
                                                                                        : translations.AddToCartNotSelected
                                                                                }`
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </Box>
                                                            <Typography
                                                                variant='semiBoldCopy'
                                                                sx={{
                                                                    textAlign: 'center',
                                                                    color: colors[1]
                                                                }}
                                                            >
                                                                {(translations[e.ClubName] ? translations[e.ClubName] : e.ClubName)
                                                                    .replace(/Pitching Wedge/g, 'PW')
                                                                    .replace(/iron/g, translations.Iron.toLowerCase())}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    backgroundColor: colors[1],
                                                                    height: '25px',
                                                                    width: '55px',
                                                                    borderBottomLeftRadius: '4px',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    alignText: 'center',
                                                                    fontSize: '1rem',
                                                                    alignSelf: 'flex-start'
                                                                }}
                                                            >
                                                                <Typography variant='semiBoldCopy' sx={{color: colors[2], marginTop: '2px'}}>
                                                                    $110
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        margin: '10px 0px'
                                                    }}
                                                >
                                                    <img
                                                        src={`https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/${e.image}`}
                                                        alt={`${e.ClubName}`}
                                                        style={{
                                                            height: `${e.image.includes('wedge') || e.image.includes('iron') ? '40px' : '50px'}`,
                                                            marginLeft: `${e.ClubName.includes('Bag') ? '30px' : '15px'}`
                                                        }}
                                                    />
                                                    <Box
                                                        sx={{
                                                            width: '63%',
                                                            marginLeft: '4%',
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'flex-start',
                                                            flexDirection: 'column',
                                                            marginTop: '4px'
                                                        }}
                                                    >
                                                        {e.flex && (
                                                            <Typography variant='mediumCopy' sx={{fontSize: '0.7rem', color: colors[1]}}>
                                                                {translations.OrderSlipShaftSpecTitle}: {e.flex}
                                                            </Typography>
                                                        )}
                                                        {e.variant && (
                                                            <Typography variant='mediumCopy' sx={{fontSize: '0.7rem', color: colors[1]}}>
                                                                {e.ClubName.includes('Putter')
                                                                    ? translations.OrderSlipModelSpecTitle
                                                                    : translations.OrderSlipSizeSpecTitle}
                                                                : {translations[e.variant]}
                                                            </Typography>
                                                        )}
                                                        {e.preference && (
                                                            <Typography variant='mediumCopy' sx={{fontSize: '0.7rem', color: colors[1]}}>
                                                                {translations.OrderSlipColorSpecTitle}: {translations[e.preference]}
                                                            </Typography>
                                                        )}
                                                        {e.risers >= 0 && (
                                                            <Box sx={{display: 'flex', justifyContnet: 'center', alignItems: 'center'}}>
                                                                <Typography
                                                                    variant='mediumCopy'
                                                                    sx={{fontSize: '0.7rem', lineHeight: '0.7rem', color: colors[1]}}
                                                                >
                                                                    {translations.OrderSlipRisersSpecTitle}: {e.risers}
                                                                </Typography>
                                                                <Tooltip title={translations.RiserTooltip} enterTouchDelay={0} placement='top'>
                                                                    <InfoOutlined sx={{color: colors[2], height: '15px'}} />
                                                                </Tooltip>
                                                            </Box>
                                                        )}
                                                        {e.length && (
                                                            <Typography variant='mediumCopy' sx={{fontSize: '0.7rem', color: colors[1]}}>
                                                                {translations.OrderSlipLengthSpecTitle}: {e.length.replace(/STD"/g, 'STD')}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </>
                                );
                            } else {
                                return <></>;
                            }
                        })}
                        {yourOptions?.map((e, i) => {
                            if (!flightData?.set_config_df[0][selectedSet].includes(e.Club) && e.ClubName !== 'Bag' && e.ClubName !== 'Putter') {
                                return (
                                    <>
                                        <Card
                                            variant='outlined'
                                            sx={{
                                                backgroundColor: colors[3],
                                                border: `1px solid ${colors[1]}`,
                                                boxShadow: `rgba(255, 255, 255, 0) 0px 0px 0px`,
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                flexGrow: 1,
                                                position: 'relative'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    flexGrow: 1,
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    width: '100%'
                                                }}
                                            >
                                                <Box sx={{width: '100%', backgroundColor: colors[11], position: 'relative'}}>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            flexDirection: 'column'
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                height: '40px'
                                                            }}
                                                        >
                                                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                <FormControlLabel
                                                                    name={e.Club ? e.Club : 'Bag'}
                                                                    onClick={event => changeSelection(event.target.name)}
                                                                    sx={{color: colors[1], marginLeft: '2px', marginRight: '2px'}}
                                                                    control={
                                                                        <Checkbox
                                                                            icon={
                                                                                <Circle
                                                                                    sx={{
                                                                                        color: colors[1],
                                                                                        backgroundColor: colors[1],
                                                                                        borderRadius: '50%',
                                                                                        fontSize: '20px'
                                                                                    }}
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <Check
                                                                                    sx={{
                                                                                        backgroundColor: colors[2],
                                                                                        color: colors[1],
                                                                                        borderRadius: '50%',
                                                                                        fontSize: '20px'
                                                                                    }}
                                                                                />
                                                                            }
                                                                            checked={
                                                                                listOfSelectedClubs.includes(e.Club) ||
                                                                                (listOfSelectedClubs.includes('Bag') && e.ClubName === 'Bag')
                                                                            }
                                                                            color='primary'
                                                                            inputProps={{
                                                                                'aria-label': `${e.ClubName} ${
                                                                                    listOfSelectedClubs.includes(e.Club) ||
                                                                                    (listOfSelectedClubs.includes('Bag') && e.ClubName === 'Bag')
                                                                                        ? translations.AddToCartSelected
                                                                                        : translations.AddToCartNotSelected
                                                                                }`
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </Box>
                                                            <Typography
                                                                variant='semiBoldCopy'
                                                                sx={{
                                                                    textAlign: 'center',
                                                                    color: colors[1]
                                                                }}
                                                            >
                                                                {(translations[e.ClubName] ? translations[e.ClubName] : e.ClubName)
                                                                    .replace(/Pitching Wedge/g, 'PW')
                                                                    .replace(/iron/g, translations.Iron.toLowerCase())}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    backgroundColor: colors[1],
                                                                    height: '25px',
                                                                    width: '55px',
                                                                    borderBottomLeftRadius: '4px',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    alignText: 'center',
                                                                    fontSize: '1rem',
                                                                    alignSelf: 'flex-start'
                                                                }}
                                                            >
                                                                <Typography variant='semiBoldCopy' sx={{color: colors[2], marginTop: '2px'}}>
                                                                    $110
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        margin: '10px 0px'
                                                    }}
                                                >
                                                    <img
                                                        src={`https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/${e.image}`}
                                                        alt={`${e.ClubName}`}
                                                        style={{
                                                            height: `${e.image.includes('wedge') || e.image.includes('iron') ? '40px' : '50px'}`,
                                                            marginLeft: `${e.ClubName.includes('Bag') ? '30px' : '15px'}`
                                                        }}
                                                    />
                                                    <Box
                                                        sx={{
                                                            width: '63%',
                                                            marginLeft: '4%',
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'flex-start',
                                                            flexDirection: 'column',
                                                            marginTop: '4px'
                                                        }}
                                                    >
                                                        {e.flex && (
                                                            <Typography variant='mediumCopy' sx={{fontSize: '0.7rem', color: colors[1]}}>
                                                                {translations.OrderSlipShaftSpecTitle}: {e.flex}
                                                            </Typography>
                                                        )}
                                                        {e.variant && (
                                                            <Typography variant='mediumCopy' sx={{fontSize: '0.7rem', color: colors[1]}}>
                                                                {e.ClubName.includes('Putter')
                                                                    ? translations.OrderSlipModelSpecTitle
                                                                    : translations.OrderSlipSizeSpecTitle}
                                                                : {translations[e.variant]}
                                                            </Typography>
                                                        )}
                                                        {e.preference && (
                                                            <Typography variant='mediumCopy' sx={{fontSize: '0.7rem', color: colors[1]}}>
                                                                {translations.OrderSlipColorSpecTitle}: {translations[e.preference]}
                                                            </Typography>
                                                        )}
                                                        {e.risers >= 0 && (
                                                            <Box sx={{display: 'flex', justifyContnet: 'center', alignItems: 'center'}}>
                                                                <Typography
                                                                    variant='mediumCopy'
                                                                    sx={{fontSize: '0.7rem', lineHeight: '0.7rem', color: colors[1]}}
                                                                >
                                                                    {translations.OrderSlipRisersSpecTitle}: {e.risers}
                                                                </Typography>
                                                                <Tooltip title={translations.RiserTooltip} enterTouchDelay={0} placement='top'>
                                                                    <InfoOutlined sx={{color: colors[2], height: '15px'}} />
                                                                </Tooltip>
                                                            </Box>
                                                        )}
                                                        {e.length && (
                                                            <Typography variant='mediumCopy' sx={{fontSize: '0.7rem', color: colors[1]}}>
                                                                {translations.OrderSlipLengthSpecTitle}: {e.length.replace(/STD"/g, 'STD')}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </>
                                );
                            } else {
                                return <></>;
                            }
                        })}
                    </Box>
                    <Box sx={{flexGrow: '1', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end', marginTop: '2rem'}}>
                        <Box
                            sx={{
                                height: '80px',
                                width: '90%',
                                padding: '0 5%',
                                backgroundColor: colors[1],
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}}>
                                <Typography variant='semiBoldCopy' sx={{fontSize: '0.9rem'}}>
                                    Sub-Total
                                </Typography>
                                <Typography variant='boldCopy' sx={{fontSize: '1.2rem'}}>
                                    ${(selectedClubObjects.length * 110).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </Typography>
                            </Box>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    console.log('This is the array with clubs the users has selected');
                                    console.log(selectedClubObjects);
                                }}
                            >
                                {translations.AddToCartCTA}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
}

export default AddToCartDrawerElement;
