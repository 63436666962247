import React, {useState, useContext} from 'react';
import {RegionStateContext} from '../RegionContext';
import {
    Alert,
    Box,
    Snackbar,
    Typography,
    Button,
    Checkbox,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide,
    IconButton,
    InputAdornment,
    CircularProgress
} from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningIcon from '@mui/icons-material/Warning';
import {Add, Close} from '@mui/icons-material';
import {transformSpecificSet} from '../util/Analytics';
import config from '../config';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function EmailPopupDialog({
    colors,
    translations,
    emailDialog,
    setEmailDialog,
    createYardageReport,
    createFittingReport,
    setConfig,
    lcDF,
    selectedSet,
    embed,
    playerHeight,
    distanceUnitImperial,
    putterPreference,
    bagPreference,
    playerLevel
}) {
    const regionState = useContext(RegionStateContext);
    const regionConfig = config[regionState.name];
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [sent, setSent] = useState(false);
    const [mainEmail, setMainEmail] = useState('');
    const [extraEmails, setExtraEmails] = useState([]);
    const [emailMalfunction, setEmailMalfunction] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);

    function isValidEmail(email) {
        // Regular expression to validate email address
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Test the input against the regular expression
        return emailRegex.test(email);
    }

    function checkIfCannotSend(acceptedTerms, mainEmail, extraEmails) {
        if (sendingEmail) return true;
        if (!acceptedTerms) return true;
        if (!isValidEmail(mainEmail)) return true;
        for (let i = 0; i < extraEmails.length; i++) {
            console.log(i);
            if (!isValidEmail(extraEmails[i])) return true;
        }
        return false;
    }

    function finishSendingEmail() {
        setTimeout(() => {
            setSendingEmail(false);
        }, 1500);
    }

    async function downloadReport() {
        setSendingEmail(true);
        try {
            const pdfBytes = await createFittingReport();

            const pdfBytesTwo = await createYardageReport();

            const base64String = btoa(pdfBytes.reduce((data, byte) => data + String.fromCharCode(byte), ''));

            const base64StringYardage = btoa(pdfBytesTwo.reduce((data, byte) => data + String.fromCharCode(byte), ''));
            const recipient_emails = {};
            recipient_emails['email1'] = mainEmail;
            extraEmails.forEach((email, index) => {
                recipient_emails[`email${index + 2}`] = email;
            });
            const trackData = transformSpecificSet(lcDF, setConfig, selectedSet, playerLevel, putterPreference, bagPreference);
            if(window.analytics){
                window.analytics.identify({email: mainEmail});
                window.analytics.track('Send Results', {recipient_email: recipient_emails});
                window.gtag('event', 'Send Results', {recipient_email: recipient_emails, embed: embed});
                trackData['b2b'] = embed;
                window.analytics.track('Fitting Results Sent', trackData);
                window.gtag('event', 'Fitting Results Sent', trackData);
            }
            let response = await fetch(`${process.env.REACT_APP_API_SURFACE_URL}/email-reports`, {
                method: 'POST',
                body: JSON.stringify({
                    to: mainEmail,
                    cc: extraEmails,
                    embed: embed,
                    attachments: [
                        {
                            content: base64String,
                            file: 'report', // Adjust the filename and type as needed
                            type: 'application/pdf'
                        },
                        {
                            content: base64StringYardage,
                            file: 'yardage', // Adjust the filename and type as needed
                            type: 'application/pdf'
                        }
                    ]
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                setEmailMalfunction(true);
                finishSendingEmail();
                return;
            }

            setSent(true);
            finishSendingEmail();
        } catch (e) {
            console.error(e);
            setEmailMalfunction(true);
            finishSendingEmail();
        }
        finishSendingEmail();
    }

    function changeExtraEmail(change, i) {
        if (extraEmails.length <= i) return;

        let temp = JSON.parse(JSON.stringify(extraEmails));
        temp[i] = change.target.value;

        setExtraEmails(temp);
    }

    function addEmail() {
        if (extraEmails.length > 2) return;
        let temp = [...extraEmails];
        temp.push('');
        setExtraEmails(temp);
    }

    function removeEmail(i) {
        let temp = [...extraEmails];
        temp.splice(i, 1);
        setExtraEmails(temp);
    }

    function close() {
        setEmailDialog(false);
        setTimeout(() => {
            setSent(false);
            setAcceptedTerms(false);
            setMainEmail('');
            setExtraEmails([]);
        }, 1000);
    }
    return (
        <>
            <Dialog open={emailDialog} onClose={close} TransitionComponent={Transition} aria-describedby='email my juniors results'>
                {!sent && (
                    <DialogTitle variant='semiBoldCopy' sx={{alignSelf: 'center', fontSize: '20px', textAlign: 'center'}}>
                        {translations.EmailPopUpTitle}
                    </DialogTitle>
                )}
                <DialogContent
                    sx={{
                        transition: '0.3s all',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexDirection: 'column',
                        maxWidth: '90%',
                        width: '350px',
                        textAlign: 'center',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        gap: '15px'
                    }}
                >
                    {sent ? (
                        <>
                            <TaskAltIcon sx={{color: colors[2], fontSize: '80px'}} />
                            <Typography variant='boldCopy' sx={{fontSize: '20px'}}>
                                {translations.EmailPopUpCompleteTitle}
                            </Typography>
                            {embed ? (
                                <>
                                    <Typography sx={{}}>{translations.EmailPopUpCompleteBodyEmbed}</Typography>
                                </>
                            ) : (
                                <>
                                    <Typography sx={{}}>{translations.EmailPopUpCompleteSubTitle}</Typography>
                                    <Typography sx={{maxWidth: '95%'}}>{translations.EmailPopUpCompleteBody}</Typography>
                                    <Box
                                        sx={{
                                            marginTop: '1rem',
                                            marginBottom: '1rem',
                                            minHeight: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                            gap: '20px',
                                            paddingBottom: '0.5rem'
                                        }}
                                    >
                                        <Button
                                            sx={{paddingTop: '10px', paddingBottom: '10px', letterSpacing: '0.8px'}}
                                            variant='contained'
                                            onClick={() => {
                                                if(window.analytics){
                                                    window.analytics.track('Order Now', {b2b: embed});
                                                }
                                                window.gtag('event', 'Order Now', {b2b: embed});
                                                if ((distanceUnitImperial && playerHeight <= 56) || (!distanceUnitImperial && playerHeight <= 142)) {
                                                    window.open(regionConfig.orderOnlineShort, '_blank', '').focus();
                                                } else {
                                                    window.open(regionConfig.orderOnlineTall, '_blank', '').focus();
                                                }
                                            }}
                                        >
                                            {translations.OrderOnlineButton}
                                        </Button>
                                        <Button
                                            sx={{paddingTop: '10px', paddingBottom: '10px', letterSpacing: '0.8px'}}
                                            variant='contained'
                                            onClick={() => {
                                                if(window.analytics){
                                                    window.analytics.track('Find a Fitter', {b2b: embed});
                                                }
                                                window.gtag('event', 'Find a Fitter', {b2b: embed});
                                                window.open(regionConfig.findFitter, '_blank', '').focus();

                                            }}
                                        >
                                            {translations.FindAFitterButton}
                                        </Button>
                                    </Box>
                                </>
                            )}
                            <Button
                                variant='close'
                                onClick={close}
                                sx={{
                                    marginTop: '10px',
                                    width: '80%',
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    letterSpacing: '0.8px'
                                }}
                            >
                                {translations.CloseButton}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Typography>{translations.EmailPopUpInfoCopy}</Typography>
                            <TextField
                                value={mainEmail}
                                onChange={e => setMainEmail(e.target.value)}
                                id='email-address'
                                label={translations.EmailFieldLabel}
                                variant='outlined'
                                sx={{width: '90%', marginTop: '0.5rem'}}
                            />
                            <Button
                                sx={{
                                    marginLeft: '5%',
                                    alignSelf: 'flex-start',
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: '0.2rem',
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                    paddingLeft: '0px'
                                }}
                                onClick={addEmail}
                                disabled={extraEmails.length > 2}
                            >
                                <Add sx={{fontSize: '1.2rem'}} />
                                {translations.AddRecipientButton}
                            </Button>
                            {extraEmails?.map((e, i) => {
                                return (
                                    <TextField
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton aria-label={translations.RemoveAddedEmailAriaLabel} onClick={() => removeEmail(i)} edge='end'>
                                                        <Close sx={{fontSize: '1.4rem'}} />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        id={`email-address-${i + 2}`}
                                        label={`${translations.EmailFieldLabel} ${i + 2}`}
                                        value={e}
                                        onChange={change => changeExtraEmail(change, i)}
                                        variant='outlined'
                                        sx={{width: '90%', marginTop: '0.5rem'}}
                                    />
                                );
                            })}
                            <Box sx={{width: '90%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '0.5rem'}}>
                                <Checkbox
                                    aria-label={translations.AcceptedTermsCheckboxAriaLabel}
                                    checked={acceptedTerms}
                                    onClick={() => setAcceptedTerms(!acceptedTerms)}
                                />
                                <Typography sx={{fontSize: '0.8rem', textAlign: 'left'}}>
                                    {translations.Agreement1}{' '}
                                    <a
                                        href={regionConfig.termsOfUseLink}
                                        target='_blank'
                                        rel='noreferrer'
                                        aria-label={translations.PingTermsAndConditionsAriaLabel}
                                        tabIndex={1}
                                        style={{
                                            'all': 'unset',
                                            'position': 'relative',
                                            '&:focus': {
                                                color: colors[2]
                                            },
                                            'cursor': 'pointer',
                                            'transition': '0.3s all'
                                        }}
                                    >
                                        <span style={{color: colors[2]}}>{translations.Agreement2}</span>
                                    </a>{' '}
                                    {translations.Agreement3}{' '}
                                    <a
                                        href={regionConfig.privacyPolicyLink}
                                        target='_blank'
                                        rel='noreferrer'
                                        aria-label={translations.PingPrivacyPolicyAriaLabel}
                                        tabIndex={1}
                                        style={{
                                            'all': 'unset',
                                            'position': 'relative',
                                            '&:focus': {
                                                color: colors[2]
                                            },
                                            'cursor': 'pointer',
                                            'transition': '0.3s all'
                                        }}
                                    >
                                        <span style={{color: colors[2]}}>{translations.Agreement4}</span>
                                    </a>
                                    .
                                </Typography>
                            </Box>
                            <Button
                                onClick={() => downloadReport()}
                                disabled={checkIfCannotSend(acceptedTerms, mainEmail, extraEmails)}
                                sx={{
                                    width: '80%',
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    letterSpacing: '0.8px',
                                    marginTop: '5px',
                                    height: '45px'
                                }}
                                variant='contained'
                            >
                                {sendingEmail ? <CircularProgress size={'20px'} /> : <> {translations.SendResultsButton}</>}
                            </Button>
                            <Button
                                variant='close'
                                onClick={close}
                                sx={{
                                    marginTop: '10px',
                                    width: '80%',
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    letterSpacing: '0.8px'
                                }}
                            >
                                {translations.CloseButton}
                            </Button>
                        </>
                    )}
                </DialogContent>
            </Dialog>
            <Snackbar
                message='I love snacks'
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={emailMalfunction}
                autoHideDuration={4000}
                onClose={() => setEmailMalfunction(false)}
            >
                <Alert icon={<WarningIcon fontSize='inherit' />} severity='warning'>
                    Error Sending Email, Please Try Again Later
                </Alert>
            </Snackbar>
        </>
    );
}

export default EmailPopupDialog;
